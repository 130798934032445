.loader {
  position: relative;
}

.loader .circle {
  position: absolute;
  width: 100%; /* Adjusted to 100% to match dynamic width */
  height: 100%; /* Adjusted to 100% to match dynamic height */
  opacity: 0;
  transform: rotate(225deg);
  animation: orbit 5s infinite;
}


.loader .circle:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background: #2b2b2b;
  border-radius: 8px;
}

.loader .circle:nth-child(1) {
  animation-delay: 0.5s;
}

.loader .circle:nth-child(2) {
  animation-delay: 0.75s;
}

.loader .circle:nth-child(3) {
  animation-delay: 1s;
}

.loader .circle:nth-child(4) {
  animation-delay: 1.25s;
}

.loader .circle:nth-child(5) {
  animation-delay: 1.5s;
}

@keyframes orbit {
  0% {
    opacity: 1;
    z-index: 99;
    transform: rotate(180deg);
    animation-timing-function: ease-out;
  }
  7% {
    opacity: 1;
    transform: rotate(300deg);
    animation-timing-function: linear;
  }
  30% {
    opacity: 1;
    transform: rotate(410deg);
    animation-timing-function: ease-in-out;
  }
  39% {
    opacity: 1;
    transform: rotate(645deg);
    animation-timing-function: linear;
  }
  70% {
    opacity: 1;
    transform: rotate(770deg);
    animation-timing-function: ease-out;
  }
  75% {
    opacity: 1;
    transform: rotate(900deg);
    animation-timing-function: ease-out;
  }
  76% {
    opacity: 0;
    transform: rotate(900deg);
  }
  100% {
    opacity: 0;
    transform: rotate(900deg);
  }
}
