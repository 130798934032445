@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '@fontsource/roboto';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Define scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Change to your preferred background color */
}

/* Define scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Change to your preferred thumb color */
  border-radius: 10px; /* Roundness of the thumb */
}

/* Define scrollbar width and height */
::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  height: 2px; /* Height of the scrollbar */
}


.transition-min-width {
  transition: min-width 0.3s ease-in-out;
}
.transition-width {
  transition: width 0.3s ease-in-out;
}
